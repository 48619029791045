/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Wordpress = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "help/wordpress/wordpress3.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Wordpress Integration',
      title: 'Integrate Wordpress with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          WordPress is one of the most commonly used content management systems for websites. It
          offers a wide range of templates and plugins to create a professional-looking website or
          blog. We created a WordPress plugin to make it easy to start tracking your website’s
          visitors’ behavior.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Learn more about your visitors</li>
          <li>Get insights from qualitative data</li>
          <li>Get the most out of your Wordpress website</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        To install the LiveSession WordPress plugin, follow the instructions in our{' '}
        <a href="/help/how-install-on-wordpress/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Wordpress);

Wordpress.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
